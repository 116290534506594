export const getParticipantVehicles = /* GraphQL */ `
  query ListVehicles(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVehicles(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        id
        vin
        make
        model
        year
        licensePlate
        isPrimary
        registrationState
        beginningOdometerReading
        currentOdometerReading
        mileage
        fuelTaxCreditCents
        mileageUserFeeCents
        mroId
        mroType
        mro {
          id
          deviceSerialNumber
        }
        participantConnectionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getParticipantMileageReports = /* GraphQL */ `
  query ListMileageReports(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelMileageReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMileageReports(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        id
        odoMileage
        tsReportDate
        photos {
          storageKey
          size
          mimeType
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTripsByUsernameByCreatedAt = /* GraphQL */ `
  query GetTripsByUsernameByCreatedAt(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByUsernameByCreatedAt(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordId
        recordTripId
        mroDeviceSerialNumber
        vehicleVin
        tsStart
        tsEnd
        polyline
        distMro
        odoMro
        fuel
        distGps
        distRoute
        odoGps
        odoMroStart
        odoMroEnd
        fuelStart
        fuelEnd
        fuelAdded
        totalFuel
        username
        vehicleId
        pilotProgramId
        mroId
        processStatus
        processMsg
        processedAt
        logs
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        todFeeCents
        tollFeeCents
        createdAt
        notes
        updatedAt
        tripSegments {
          items {
            tripId
            id
            paymentStatus
          }
        }
        reviewStatus
      }
      nextToken
    }
  }
`;

export const getParticipantMroDevicePreference = /* GraphQL */ `
  query GetParticipant($username: String!) {
    getParticipant(username: $username) {
      mroDevicePreference
    }
  }
`;
