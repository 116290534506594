import React from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import ParticipantVehicles from 'pages/Admin/Participant/components/ParticipantVehicles';

const VehicleInfo = ({ user }) => {
  const { username } = user;

  return (
    <Container component="main">
      <ParticipantVehicles
        username={username}
        title="My Vehicles"
        viewer="participant"
      />
    </Container >
  );
};

VehicleInfo.propTypes = {
  user: PropTypes.object,
};

export default VehicleInfo;
