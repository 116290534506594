import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Logo from 'components/Logo';

const PilotClosed = () => {
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div>
          <Grid container justify="center">
            <Grid item xs={6}>
              <Box marginBottom={4} marginTop={6}>
                <Logo width="100%" withETCoalition={true} />
              </Box>
            </Grid>
          </Grid>
          <Typography component="h5" variant="h5" color="primary" gutterBottom>
            Enrollment for the GA Mileage-Based User Fee (MBUF) Study is now closed.
          </Typography>
          <p>
            Thank you for your interest in participating in the study, powered by
            Transurban. The Study has reached its goal of 150 participants and
            enrollment is now closed. If you weren’t able to join this Study,
            but are interested in future opportunities, please email us at
            ga@ga-mbuf.org to be added to a list.
          </p>
          <p>
            If you are already enrolled and have questions,
            please contact us at <a mailto="ga@ga-mbuf.org">ga@ga-mbuf.org</a> or
            000-000-0000.
          </p>
        </div>
      </Container>
    </>
  );
};

PilotClosed.propTypes = {};
export default PilotClosed;
