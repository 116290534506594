import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import VerticalTabs from 'components/Tab/VerticalTabs';

import TripSegmentsTable from 'pages/Admin/components/TripSegmentsTable';
import TripAdjustmentsTable from 'pages/Admin/components/TripAdjustmentsTable';
import TripsTable from 'pages/Admin/components/TripsTable';

import { asyncListAll } from 'utilities/graph';
import {
  getTripsByUsernameByCreatedAt,
  getTripSegmentsByTransaction,
  getTripAdjustmentsByTransactionByPaymentStatus,
} from 'graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  content: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function Transaction({
  id: inId,
  createdAt, // participant viewer only for trips loading
  username, // participant viewer only for trips loading
  computedMatch,
  viewer = 'admin',
}) {
  const classes = useStyles();

  const [id, setId] = useState();
  const [isNested, setIsNested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (inId) {
      setId(inId);
      setIsNested(true);
    } else if (computedMatch) {
      const { params: { id } } = computedMatch;
      setId(id);
      setIsNested(false);
    }
  }, [inId, computedMatch]);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsLoading(true);
        try {
          const tabs = [];
          if (viewer === 'admin') {
            const [
              segments,
              adjustments,
            ] = await Promise.all([
              asyncListAll(getTripSegmentsByTransaction, { transactionId: id }),
              asyncListAll(getTripAdjustmentsByTransactionByPaymentStatus, { transactionId: id }),
            ]);

            if (segments.length > 0) {
              tabs.push({
                label: 'Trip Segments',
                component: <TripSegmentsTable data={segments} />,
              });
            }

            if (adjustments.length > 0) {
              tabs.push({
                label: 'Trip Adjustments',
                component: <TripAdjustmentsTable data={adjustments} />,
              });
            }
          }

          if (viewer === 'participant') {
            const from = moment(createdAt).startOf('month').format('YYYY-MM-DD');
            const to = moment(createdAt).endOf('month').format('YYYY-MM-DD');
            const trips = await asyncListAll(getTripsByUsernameByCreatedAt, {
              username,
              createdAt: {
                between: [from, to],
              },
              sortDirection: 'DESC',
            }, { bypassCache: true });
            tabs.push({
              label: 'Trips',
              component: <TripsTable data={trips} />,
            });
          }

          setTabs(tabs);
        } catch (e) {
          console.warn(e);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [id, classes, isNested, viewer, username, createdAt]);

  if (!id) {
    return null;
  }

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <VerticalTabs
      tabs={tabs}
      isNested={isNested}
    />
  );
}

Transaction.propTypes = {
  id: PropTypes.string,
  createdAt: PropTypes.string,
  username: PropTypes.string,
  computedMatch: PropTypes.object,
  viewer: PropTypes.string,
};
