/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';

export const useChart = (chartRef, chartData) => {
  const [activeMonthIndex, setActiveMonthIndex] = useState(10);
  useEffect(() => {
    if (chartRef.current && chartData) {
      // configure chart
      const chart = new ApexCharts(chartRef.current, {
        chart: {
          type: 'bar',
          events: {
            dataPointSelection: (e, chart, opts) => {
              const { dataPointIndex } = opts;
              setActiveMonthIndex(dataPointIndex);
            },
          },
        },
        series: [{
          name: 'Balance',
          data: Object.values(chartData).map((month) => month.data),
        }],
        xaxis: {
          categories: Object.values(chartData).map((month) => month.name),
          labels: {
            style: {
              colors: Array(11).fill('#0A4842'),
              fontSize: '15px',
              fontWeight: 600,
            },
            offsetY: 5,
            minHeight: 40,
          },
        },
        yaxis: {
          title: {
            text: 'Dollars',
          },
        },
        dataLabels: {
          formatter: (val, opt) => {
            return `$${(val).toFixed(2)}`;
          },
        },
        tooltip: {
          custom: ({ dataPointIndex }) => {
            const {
              extra: {
                trips,
                mileage,
                fuel,
              },
            } = chartData[dataPointIndex];
            return (
              `<div class="MuiPaper-root MuiAlert-root MuiAlert-standardInfo MuiPaper-elevation0" role="alert">
                <div class="MuiAlert-icon">
                  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z"></path>
                  </svg>
                </div>
                <div class="MuiAlert-message">
                  <div>Total trips: ${trips}</div>
                  <div>Total mileage: ${mileage.toFixed(1)}</div>
                  <div>Total fuel consumed: ${fuel.toFixed(1)}</div>
                </div>
              </div>`
            );
          },
          fixed: {
            position: 'topRight',
          },
        },
        fill: {
          colors: ['#00AC6F'],
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.3,
            },
            active: {
              type: 'darken',
              value: 0.5,
            },
          },
        },
        title: {
          text: 'Last 12 Months',
          align: 'center',
          style: {
            fontSize: '18px',
          },
        },
      });

      chart.render();
    }
  }, [chartRef, chartData]);

  return activeMonthIndex;
};
