import React from 'react';

import GpsFixedSharpIcon from '@material-ui/icons/GpsFixedSharp';
import GpsOffSharpIcon from '@material-ui/icons/GpsOffSharp';
import DirectionsCarSharpIcon from '@material-ui/icons/DirectionsCarSharp';

export const mroDeviceFriendlyName = (value) => {
  switch (value) {
    case 'automatedWithLocation':
      return 'Device With Location';
    case 'automatedWithoutLocation':
      return 'Device Without Location';
    case 'telematics':
      return 'Telematics';
    default:
      return 'Not Specified';
  }
};

export const mroDevicePreferences = [{
  label: 'Device With Location',
  participantLabel: 'GPS Enabled',
  value: 'automatedWithLocation',
  icon: <GpsFixedSharpIcon fontSize="large" />,
  checkedIcon: <GpsFixedSharpIcon fontSize="large" color="primary" />,
}, {
  label: 'Device Without Location',
  participantLabel: 'GPS Disabled',
  value: 'automatedWithoutLocation',
  icon: <GpsOffSharpIcon fontSize="large" />,
  checkedIcon: <GpsOffSharpIcon fontSize="large" color="primary" />,
}, {
  label: 'Telematics',
  participantLabel: 'Telematics',
  value: 'telematics',
  icon: <DirectionsCarSharpIcon fontSize="large" />,
  checkedIcon: <DirectionsCarSharpIcon fontSize="large" color="primary" />,
}];
