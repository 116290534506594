export const decodeVin = async (vin) => {
  const endpoint = `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vin}?format=json`;
  let decodedResult;

  try {
    const response = await fetch(endpoint);
    const parsedResponse = await response.json();
    const { Results: result } = parsedResponse;
    decodedResult = result[0];
  } catch (e) {
    console.error(e);
    throw new Error('nhtsa is not available');
  }

  const {
    Make: make,
    Model: model,
    ModelYear: year,
    Trim: trim,
    EngineCylinders: cylinders,
    FuelTypePrimary: fuelType1,
    FuelTypeSecondary: fuelType2,
    ErrorCode: errorCode,
    ErrorText: errorText,
  } = decodedResult;

  if (make === '' || model === '' || year === '' || fuelType1 === '') {
    throw new Error(`failed to decode vin (${vin})`);
  }

  return {
    make,
    model,
    trim,
    year,
    cylinders,
    fuelType1,
    fuelType2,
    errorCode,
    errorText,
  };
};
