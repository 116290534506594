import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import UpdateIcon from '@material-ui/icons/Update';

import Table from 'components/Table';
import {
  mroDevicePreferences,
  states,
  vehicleMakesAndModels,
} from 'utilities/constants';
import { asyncRetryMutation } from 'utilities/graph';
import { updateVehicle } from 'graphql/mutations';

const VehicleTable = ({
  data: inData,
  title = 'Vehicles',
  description = '',
  viewer = 'admin',
  onHandleCreate,
  onHandleRemove,
}) => {
  const [data, setData] = useState();
  const multipleVehiclesEnabled = localStorage.getItem(`ruc:configuration:FEATURE_MULTIPLE_VEHICLES`) === 'enabled';

  const columns = [
    {
      name: 'mroId',
      label: 'ID',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mro.deviceSerialNumber',
      label: 'MRO ID',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'isPrimary',
      label: 'Primary',
      type: 'checkbox',
      edit: {
        type: 'checkbox',
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'vin',
      label: 'VIN',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return (value) ? value : (
            <Tooltip
              title="Waiting for vehicle to report data."
              placement="right"
            >
              <UpdateIcon />
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'make',
      label: 'Make',
      edit: {
        type: 'select',
        menu: (data) => {
          return vehicleMakesAndModels.map(({ brand }) => brand.toUpperCase());
        },
        rerenderAfterSelect: true, // to refresh model menu
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'model',
      label: 'Model',
      edit: {
        type: 'select',
        menu: (data) => {
          const { models } = vehicleMakesAndModels.find(({ brand }) => {
            return brand.toLowerCase() === data.make.toLowerCase();
          });
          return models;
        },
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'year',
      label: 'Year',
      edit: {
        type: 'number',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'licensePlate',
      label: 'License Plate',
      edit: {
        type: 'text',
      },
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'registrationState',
      label: 'State',
      edit: {
        type: 'select',
        menu: Object.keys(states),
      },
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'beginningOdometerReading',
      label: 'Beginning Odometer Reading',
      type: 'mileage',
      edit: viewer === 'admin' ? {
        type: 'number',
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'currentOdometerReading',
      label: 'Current Odometer Reading',
      type: 'mileage',
      edit: viewer === 'admin' ? {
        type: 'number',
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mileage',
      label: 'Mileage',
      type: 'mileage',
      edit: viewer === 'admin' ? {
        type: 'number',
      } : undefined,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mroType',
      label: 'Reporting Type',
      edit: {
        type: 'select',
        menu: mroDevicePreferences,
      },
      options: {
        filter: false,
        display: true,
        customBodyRender(item) {
          const preference = mroDevicePreferences.find(({ value }) => value === item) || {};
          const { label = 'N/A' } = preference;
          return label;
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      type: 'datetime',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      type: 'datetime',
      options: {
        display: viewer === 'admin',
        filter: false,
        sort: true,
      },
    },
  ].filter((x) => {
    return viewer === 'participant' && x.options ? x.options.display !== false : true;
  });

  const onUpate = async (item, dataIndex) => {
    const input = {
      id: item.id,
      username: item.username,
      updatedBy: localStorage.getItem('ruc:username'),
    };
    columns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = item[name];
      }
    });
    await asyncRetryMutation(updateVehicle, { input });

    Object.assign(data[dataIndex], input);
    setData([...data]);
  };

  useEffect(() => {
    setData(inData);
  }, [inData]);

  return (
    <div data-test-id="vehicles-table">
      <Table
        title={title}
        description={description}
        data={data}
        columns={columns}
        options={viewer === 'participant' ? {
          download: false,
          search: false,
          print: false,
          viewColumns: false,
          filter: false,
        } : {}}
        onHandleCreateItem={multipleVehiclesEnabled ? onHandleCreate : undefined}
        onHandleRemoveItem={viewer === 'admin' ? onHandleRemove : undefined}
        onUpdateItem={onUpate}
      />
    </div>
  );
};

VehicleTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array.isRequired,
  viewer: PropTypes.string,
  onHandleCreate: PropTypes.func,
  onHandleRemove: PropTypes.func,
  onHandleSetupTelematics: PropTypes.func,
};

export default VehicleTable;
