/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { decodeVin } from 'utilities/nhtsa';
import { asyncRetryMutation } from 'utilities/graph';

import { useStyles } from './commonStyles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import ControlledInput from 'components/Form/ControlledInput';

import {
  validateVehicleVin,
  validatePilotEligibility,
} from 'utilities/vehicle';
import {
  vehicleMakesAndModels,
  vehicleTypes,
  states,
} from 'utilities/constants';
import {
  shellVehicle,
} from 'utilities/constants/shellModels';
import { asyncGet } from 'utilities/graph';
import {
  getEPAVehiclesByMakeByYear,
  getVehicleByVin,
} from 'graphql/queries';
import {
  updateParticipant,
} from 'graphql/mutations';
import {
  createVehicle,
  updateVehicle,
} from './graphql';

const RegisterVehicle = ({
  username,
  vehicle = shellVehicle,
  onCompleteStep,
  onPreviousStep,
  options = {},
}) => {
  const classes = useStyles();

  // registration & participant management screens
  const {
    previousStepCopy = 'Back',
    submitCopy = 'Continue',
    title = 'Vehicle Information',
  } = options;

  // feature flags
  const registerWithVinEnabled = localStorage.getItem(`ruc:configuration:FEATURE_REGISTER_WITH_VIN`) === 'enabled';

  // form states
  const { control, errors, handleSubmit, formState, watch, setValue } = useForm();
  const { isSubmitting } = formState;
  const watchMake = watch('make');
  const watchYear = watch('year');
  const watchModel = watch('model');

  const [error, setError] = useState(false);
  const [eligibilityErrors, setEligibilityErrors] = useState([]);
  const [epaVehicles, setEpaVehicles] = useState([]);

  useEffect(() => {
    if (!watchMake || !watchYear || watchYear.length !== 4) {
      return;
    }

    (async () => {
      const {
        data: {
          getEPAVehiclesByMakeByYear: {
            items,
          },
        },
      } = await asyncGet(getEPAVehiclesByMakeByYear, {
        make: watchMake.toUpperCase(),
        year: {
          eq: watchYear,
        },
      }, { bypassCache: true });

      // unique models
      const distinct = [];
      const unique = [];
      for (let i = 0; i < items.length; i++) {
        if (!unique[items[i].model]) {
          distinct.push(items[i]);
          unique[items[i].model] = true;
        }
      }

      setEpaVehicles(distinct);
    })();
  }, [watchMake, watchYear]);

  let modelLabel = 'Model';
  if (!watchYear && !watchMake) {
    modelLabel = 'Please enter the year and make of the vehicle';
  } else if (!watchYear) {
    modelLabel = 'Please enter the year of the vehicle';
  } else if (!watchMake) {
    modelLabel = 'Please enter the make of the vehicle';
  }

  const inputs = [{
    autoFocus: true,
    type: 'number',
    name: 'year',
    defaultValue: '',
    label: 'Year',
    required: true,
    invalidText: 'Vehicle year is required',
    disabled: registerWithVinEnabled,
  }, {
    type: 'select',
    name: 'make',
    defaultValue: '',
    label: 'Make',
    required: true,
    options: vehicleMakesAndModels.map((manufacturer) => {
      return {
        value: manufacturer.brand.toUpperCase(),
        label: manufacturer.brand.toUpperCase(),
      };
    }),
    invalidText: 'Vehicle make is required',
    disabled: registerWithVinEnabled,
  }, {
    type: 'select',
    name: 'model',
    defaultValue: '',
    label: modelLabel,
    required: true,
    disabled: !watchYear || !watchMake || epaVehicles.length === 0,
    errors: (watchYear && watchMake && epaVehicles.length === 0) ? { model: true } : {},
    helperText: `No models found for a ${watchYear} ${watchMake}`,
    options: epaVehicles.map((vehicle) => {
      return {
        value: vehicle,
        label: `${vehicle.model}`,
        testValue: vehicle.id,
      };
    }).sort((a, b) => a.label > b.label ? 1 : -1),
    invalidText: (watchYear && watchMake && epaVehicles.length === 0) ? `No models found for a ${watchYear} ${watchMake}` : 'Vehicle model is required',
    setValue,
  }, {
    type: 'select',
    name: 'type',
    defaultValue: vehicle.type.replace(/\b\w/g, (c) => {
      return c.toUpperCase();
    }),
    label: 'Vehicle Type',
    required: true,
    options: vehicleTypes.map((type) => {
      return {
        value: type,
        label: type,
      };
    }),
    invalidText: 'Vehicle type is required',
    disabled: registerWithVinEnabled,
  }, {
    type: 'select',
    name: 'registrationState',
    defaultValue: vehicle.registrationState,
    label: 'Registered State',
    required: true,
    options: Object.keys(states).map((state) => {
      return {
        value: state,
        label: states[state],
      };
    }),
    invalidText: 'State of registration is required',
  }, {
    type: 'text',
    name: 'licensePlate',
    defaultValue: vehicle.licensePlate,
    label: 'License Plate No.',
    required: true,
    invalidText: 'License plate is required',
    setValue,
  }];

  if (registerWithVinEnabled) {
    inputs.unshift({
      autoFocus: true,
      type: 'text',
      name: 'vin',
      defaultValue: '',
      label: 'Vehicle VIN',
      required: true,
      validate: validateVehicleVin,
      invalidText: 'Vehicle VIN is required',
      inputProps: {
        onBlur: async (event) => {
          try {
            const { make, year, fuelType1, fuelType2 } = await decodeVin(event.target.value);
            setValue('year', year);
            setValue('make', make.toUpperCase());
            switch (fuelType1) {
              case 'Gasoline':
                setValue('type', fuelType2 === 'Electric' ? 'Hybrid' : 'Gas');
                break;
              case 'Electric':
                setValue('type', 'Electric');
                break;
              default:
                console.warn('unsupported vehicle fuel type');
            }
            setValue('type', fuelType1 === 'Gasoline' ? 'Gas' : '');
          } catch (e) {
            console.warn(e);
          }
        },
      },
    });
  } else {
    if (watchYear >= 1996 && watchYear <= 2006) {
      inputs.push({
        type: 'text',
        name: 'vin',
        label: 'Vehicle VIN',
        required: true,
        validate: validateVehicleVin,
        invalidText: 'Please provide a valid Vehicle VIN',
        setValue,
      });
    }
  }

  async function handleRegisterVehicle({
    make,
    model,
    year,
    type,
    licensePlate,
    registrationState,
    vin,
  }) {
    // pilot eligibility
    const { isValid, errors } = validatePilotEligibility(year, type);
    if (!isValid) {
      setEligibilityErrors(errors);
      return;
    }

    const epaVehicle = Object.assign({}, watchModel);
    const input = {
      username,
      vin,
      make: epaVehicle.make || make,
      model: epaVehicle.model || model,
      year: epaVehicle.year || year,
      type: type.toLowerCase(),
      licensePlate,
      registrationState,
      mroId: 'N/A',
      mroType: 'N/A',
      epaVehicleId: epaVehicle.id,
      epaVehicleCombinedKpl: epaVehicle.mpgCombined,
      epaVehicleCombinedMpg: epaVehicle.kplCombined,
      createdBy: localStorage.getItem('ruc:username'),
      updatedBy: localStorage.getItem('ruc:username'),
      isPrimary: true,
    };

    const vehicleExists = (vehicle.id) ? true : false;
    if (vehicleExists) {
      input.beginningOdometerReading = vehicle.beginningOdometerReading;
      input.createdBy = vehicle.createdBy;
      input.currentOdometerReading = vehicle.currentOdometerReading;
      input.gotoll = vehicle.gotoll;
      input.fuelTaxCreditCents = vehicle.fuelTaxCreditCents;
      input.id = vehicle.id;
      input.locations = vehicle.locations;
      input.logs = vehicle.logs;
      input.mileage = vehicle.mileage;
      input.mileageUserFeeCents = vehicle.mileageUserFeeCents;
      input.mroType = vehicle.mroType;
      input.mroId = vehicle.mroId;
      input.reports = vehicle.reports;
      input.updatedBy = vehicle.updatedBy;
    }

    let vehicleRecord;
    try {
      /**
       * Users who return to the vehicle section of
       * the registration flow before they complete
       * onboarding
       */
      if (vehicleExists) {
        /* eslint-disable-next-line */
        const [updatedVehicle, updatedParticipant] = await Promise.all([
          asyncRetryMutation(updateVehicle, { input }),
          asyncRetryMutation(updateParticipant, {
            input: {
              username,
              mroDevicePreference: null, // reset for the reporting options screen
              updatedBy: localStorage.getItem('ruc:username'),
            },
          }),
        ]);

        vehicleRecord = updatedVehicle.data.updateVehicle;
      } else {
        // check for duplicates (doesn't need to be FF locked)
        if (vin) {
          const existingVehicle = await asyncGet(getVehicleByVin, { vin });
          const {
            data: {
              getVehicleByVin: {
                items,
              },
            },
          } = existingVehicle;
          if (items.length > 0) {
            setError('This vehicle has already been registered');
            return;
          }
        }

        // create vehicle
        const response = await asyncRetryMutation(createVehicle, { input });
        vehicleRecord = response.data.createVehicle;
      }

      onCompleteStep(Object.assign({}, input, vehicleRecord));
    } catch (e) {
      setError(e.message);
      return;
    }
  }

  function handleCloseError() {
    setError(false);
  }

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">{title}</Typography>
      {eligibilityErrors.length === 0 ? (
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleRegisterVehicle)}
          noValidate
        >
          <Grid container spacing={2}>
            {registerWithVinEnabled && (
              inputs.splice(0, 1).map(((input, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ControlledInput
                      control={control}
                      errors={errors}
                      {...input}
                    />
                  </Grid>
                );
              }))
            )}
            {inputs.splice(0, 2).map(((input, index) => {
              return (
                <Grid item xs={12} sm={6} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            }))}
            {inputs.map((input, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <ControlledInput
                    control={control}
                    errors={errors}
                    {...input}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12} sm={6}>
              <Button
                type="button"
                size="large"
                fullWidth
                variant="contained"
                color="inherit"
                className={classes.secondaryAction}
                onClick={() => {
                  onPreviousStep();
                }}
              >
                {previousStepCopy}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {submitCopy}
              </Button>
            </Grid>
          </Grid>
        </form>
      ) : (
        <div className={classes.paper}>
          <strong id="eligibility-failure">Unfortunately your vehicle does not meet the eligibility requirements of this MBUF Study:</strong>
          <ul>
            {eligibilityErrors.map((error, index) => {
              return (<li id={`eligibility-reason-${index}`} key={index}>{error}</li>);
            })}
          </ul>
          <Button
            type="button"
            size="large"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={() => {
              setEligibilityErrors([]);
            }}
          >
            Update Vehicle Info
          </Button>
        </div>
      )
      }
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          onClose={handleCloseError}>
          {error}
        </Alert>
      </Snackbar>
    </div >
  );
};

RegisterVehicle.propTypes = {
  username: PropTypes.string,
  vehicle: PropTypes.object,
  onCompleteStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  options: PropTypes.object,
};

export default RegisterVehicle;
