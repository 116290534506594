import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'index.js';

import { ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import RegisterVehicle from 'pages/Auth/components/RegisterVehicle';

const CreateVehicleDialog = ({ isOpen, onClose, username }) => {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        data-test-id="register-vehicle-dialog"
        open={isOpen}
        onClose={onClose}
        disableBackdropClick={true}
      >
        <DialogContent>
          <RegisterVehicle
            username={username}
            onCompleteStep={(vehicle) => {
              onClose(true, vehicle);
            }}
            onPreviousStep={() => {
              onClose();
            }}
            options={{
              previousStepCopy: 'Cancel',
              submitCopy: 'Create Vehicle',
              title: 'Register Another Vehicle',
            }}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

CreateVehicleDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  username: PropTypes.string,
};

export default CreateVehicleDialog;
