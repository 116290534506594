import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { useStyles } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import NestedTableContainer from 'components/Table/NestedTableContainer';
import MroEventsTable from 'pages/Admin/components/MroEventsTable';
import RemoveMRODeviceDialog from './components/RemoveMRODeviceDialog';
import VehicleDetailsTooltip from 'pages/Admin/components/VehicleDetailsTooltip';

import {
  asyncListAll,
  asyncRetryMutation,
} from 'utilities/graph';
import {
  updateMileageReportingOption,
} from 'graphql/mutations';
import {
  listMileageReportingOptions,
} from './graphql';

import Table from 'components/Table';

export default function MRODevices({ username = null }) {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  const [mros, setMros] = useState([]);
  const [removeMroDeviceDialogIsOpen, setRemoveMroDeviceDialogIsOpen] = useState(false);
  const [actionMroDevice, setActionMroDevice] = useState(null);

  const tableColumns = [{
    name: 'username',
    options: {
      display: false,
    },
  }, {
    name: 'deviceSerialNumber',
    label: 'Serial Number',
  }, {
    name: 'shippedDate',
    label: 'Shipped Date',
    type: 'date',
    edit: {
      type: 'date',
    },
  }, {
    name: 'deliveredDate',
    label: 'Delivered Date',
    type: 'date',
    edit: {
      type: 'date',
    },
  }, {
    name: 'activationDate',
    label: 'Activation Date',
    type: 'date',
  }, {
    name: 'lastReportedDate',
    label: 'Last Report At',
    type: 'datetime',
  }, {
    name: 'participant.fullName',
    label: 'Participant Name',
  }, {
    name: 'participant.email',
    label: 'Participant Email',
    options: {
      customBodyRender(email, metadata) {
        const { rowData } = metadata;
        return (
          <Link to={`/participant/${rowData[0]}`}>
            {email}
          </Link>
        );
      },
    },
  }, {
    name: 'participant.status',
    label: 'Participant Status',
  }, {
    name: 'vehicle.vin',
    label: 'Vehicle Details',
    options: {
      display: true,
      filter: true,
      sort: true,
      customBodyRenderLite(dataIndex) {
        const mro = mros ? mros[dataIndex] : null;
        if (!mro || !mro.vehicle) {
          return;
        }

        const { vehicle = null } = mro;
        return (
          <VehicleDetailsTooltip
            username={username}
            vehicle={vehicle}
          />
        );
      },
    },
  }, {
    name: 'gpsEnabled',
    label: 'GPS Enabled',
    type: 'checkbox',
    edit: {
      type: 'checkbox',
    },
  }];

  const tableOptions = {
    enableNestedDataAccess: '.',
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData, rowMeta) {
      const { deviceSerialNumber } = mros[rowMeta.dataIndex];
      return (
        <NestedTableContainer columns={tableColumns}>
          <MroEventsTable deviceSerialNumber={deviceSerialNumber} />
        </NestedTableContainer>
      );
    },
  };

  const onUpdate = async (item, dataIndex) => {
    const row = mros[dataIndex];
    const input = {
      username: row.username,
      id: row.id,
      vehicleId: row.vehicleId,
      updatedBy: localStorage.getItem('ruc:username'),
    };

    tableColumns.forEach(({ name, edit }) => {
      if (edit) {
        input[name] = (name === 'shippedDate' || name === 'deliveredDate') ?
          moment(item[name]).toISOString() : item[name];
      }
    });

    await asyncRetryMutation(updateMileageReportingOption, { input });

    Object.assign(mros[dataIndex], input);
    setMros([...mros]);
  };

  const onDelete = async (mroDevice) => {
    setRemoveMroDeviceDialogIsOpen(true);
    setActionMroDevice(mroDevice);
  };

  const handleCloseRemoveMroDeviceDialog = () => {
    setRemoveMroDeviceDialogIsOpen(false);
    setActionMroDevice(null);
    setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const mroRecords = await asyncListAll(
          listMileageReportingOptions,
          undefined,
          { bypassCache: true },
        );

        const hydratedMros = mroRecords.map((mro) => {
          mro.participant.fullName = `${mro.participant.firstName} ${mro.participant.lastName}`;
          return mro;
        });

        if (username) {
          const userMros = hydratedMros.filter(({ username: mroUsername }) => {
            return mroUsername === username;
          });
          setMros(userMros);
        } else {
          setMros(hydratedMros);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username, forceRefresh]);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Fab
        color="primary"
        className={classes.floatingCreateButton}
        onClick={() => {
          history.push('/mro-devices/register');
        }}
      >
        <Tooltip
          title="Associate Device to Participant"
          placement="bottom"
        >
          <AddIcon />
        </Tooltip>
      </Fab>
      <Fab
        color="primary"
        className={classes.floatingBulkUploadButton}
        onClick={() => {
          history.push('/mro-devices/bulk-register');
        }}
      >
        <Tooltip
          title="Bulk Register Devices"
          placement="bottom"
        >
          <CloudUploadIcon />
        </Tooltip>
      </Fab>
      <Table
        title="Devices"
        options={tableOptions}
        columns={tableColumns}
        data={mros}
        onUpdateItem={onUpdate}
        onHandleRemoveItem={onDelete}
      />
      <RemoveMRODeviceDialog
        isOpen={removeMroDeviceDialogIsOpen}
        onClose={handleCloseRemoveMroDeviceDialog}
        mroDevice={actionMroDevice}
      />
    </div>
  );
}

MRODevices.propTypes = {
  username: PropTypes.string,
};
