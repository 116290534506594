/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Grid from '@material-ui/core/Grid';
import VerticalTabs from 'components/Tab/VerticalTabs';

import BasicInfo from './components/BasicInfo';
import BillingInfo from './components/BillingInfo';
import Inquiries from './components/Inquiries';
import MileageReport from './components/MileageReport';
import MROInfo from './components/MROInfo';
import ParticipantTrips from 'pages/Admin/Participant/components/ParticipantTrips';
import VehicleInfo from './components/VehicleInfo';
import Statements from './components/Statements';
import Connections from './components/Connections';
import Dashboard from './components/Dashboard';

import { asyncGet, asyncListAll } from 'utilities/graph';
import { getParticipant, listVehicles } from 'graphql/queries';
import { COLLECT_BILLING_INFORMATION } from 'utilities/constants/paymentStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

const MyAccount = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const { username } = user;

  const [participant, setParticipant] = useState(null);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    (async () => {
      const [
        { data: { getParticipant: participant } },
        participantVehicles,
      ] = await Promise.all([
        asyncGet(getParticipant, { username }),
        asyncListAll(listVehicles, { username }),
      ]);

      setParticipant(participant);
      setVehicles(participantVehicles);

      localStorage.setItem('ruc:name', `${participant.firstName} ${participant.lastName}`);
      localStorage.setItem('ruc:email', participant.email);
    })();
  }, [username]);

  if (!participant) {
    return <div />;
  }

  if (participant.status === 'created') {
    return history.push('/register');
  }

  if (participant.status === 'onboarded') {
    return (
      <Grid container justify="center" data-test-id="account-review">
        <Grid item xs={12} sm={9} md={6}>
          <Alert severity="info">
            <AlertTitle>Registration Complete</AlertTitle>
            <p>
              Thank you for completing your MBUF Study enrollment. We will review your application within 2 – 3 business days. If your application is approved, we will email you with your account details and next steps.
            </p>
            <p>If you need help, please contact the MBUF Study Help Desk at <a href="mailto:ga@ga-mbuf.org">ga@ga-mbuf.org</a></p>
            <p>Safe travels.</p>
          </Alert>
        </Grid>
      </Grid>
    );
  }

  const tabs = [
    {
      label: 'Dashboard',
      component: <Dashboard user={user} />,
    },
    {
      label: 'My Info',
      component: <BasicInfo user={user} />,
    },
    {
      label: 'Vehicles',
      component: <VehicleInfo user={user} />,
    },
    {
      label: 'Trips',
      component: <ParticipantTrips
        username={username}
        title="My Trips"
        viewer="participant"
        pilotProgram={participant.pilotProgram.shortName}
      />,
      hideFromMenu: !accountHasDevice(vehicles),
    },
    {
      label: 'Create Mileage Report',
      component: <MileageReport username={username} />,
      hideFromMenu: participant ? participant.pilotProgram.shortName !== 'OB-MBUF' : true,
    },
    {
      label: 'Reporting Device',
      component: <MROInfo user={user} />,
      hideFromMenu: !accountHasDevice(vehicles),
    },
    {
      label: 'Connections',
      component: <Connections user={user} />,
      hideFromMenu: !accountHasTelematics(vehicles),
    },
    {
      label: 'Billing Info',
      component: <BillingInfo user={user} />,
      hideFromMenu: !COLLECT_BILLING_INFORMATION,
    },
    {
      label: 'Statements',
      component: <Statements username={username} />,
    },
    {
      label: 'Inquiries',
      component: <Inquiries username={username} />,
    },
  ].filter(({ hideFromMenu }) => !hideFromMenu);

  function accountHasTelematics(vehicles) {
    return vehicles.filter(({ mroType }) => {
      return mroType === 'telematics';
    }).length > 0;
  }

  function accountHasDevice(vehicles) {
    return vehicles.filter(({ mroType }) => {
      return (
        mroType === 'automatedWithLocation' ||
        mroType === 'automatedWithoutLocation'
      );
    }).length > 0;
  }

  function accountHasPendingConnections(vehicles) {
    return vehicles.length > 0 && vehicles.filter(({ mroType, participantConnectionId }) => {
      return mroType === 'telematics' && !participantConnectionId;
    }).length > 0;
  }

  return (
    <div className={classes.root}>
      <Grid>
        <Grid className={classes.alert}>
          {accountHasPendingConnections(vehicles) && (
            <Alert severity="warning">
              One or more of your registered vehicles requires a telematics connection. <a href="/dashboard?tab=Connections">Set up the connection here.</a>
            </Alert>
          )}
        </Grid>
        <Grid>
          <VerticalTabs
            tabs={tabs}
          />
        </Grid>
      </Grid>
    </div>
  );
};

MyAccount.propTypes = {
  user: PropTypes.object,
};

export default MyAccount;
