import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Parser } from 'json2csv';
import { CSVLink } from 'react-csv';

import {
  getTripsByUsernameByCreatedAt,
  listParticipants,
  getPilotProgram,
  getVehicle,
} from 'graphql/queries';
import { asyncGet, asyncListAll } from 'utilities/graph';
import { sortBy } from 'utilities/sorting';
import MultiSelect from 'components/MultiSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },

}));

export default function TripReport() {
  const classes = useStyles();
  const csvLinkRef = React.useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [from, setFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    (async () => {
      const allParticipants = (await asyncListAll(listParticipants))
        .filter(({ participantPilotProgramId }) => participantPilotProgramId)
        .sort(sortBy('lastName'))
        .sort(sortBy('firstName'))
        .map((item) => {
          item.fullName = `${item.firstName} ${item.lastName}`;
          item.displayName = `${item.fullName} (${item.email})`;
          return item;
        });

      setParticipants(allParticipants);
    })();
  }, []);

  const query = async () => {
    try {
      setIsSubmitting(true);

      const updatedTrips = [];
      const vehicles = [];

      console.log(selectedParticipants);

      const processPromises = await selectedParticipants.map(async (participant) => {
        const searchParams = {
          username: participant.username,
          createdAt: {
            between: [from, to],
          },
        };

        const [
          trips,
          { data: { getPilotProgram: pilotProgram } },
        ] = await Promise.all([
          asyncListAll(getTripsByUsernameByCreatedAt, searchParams),
          asyncGet(getPilotProgram, { id: participant.participantPilotProgramId }),
        ]);

        await trips.reduce(async (chain, trip) => {
          await chain;

          let matchedVehicle = vehicles.find(({ id }) => id === trip.vehicleId);
          if (!matchedVehicle) {
            const { data: { getVehicle: vehicle } } = await asyncGet(getVehicle, { username: participant.username, id: trip.vehicleId });
            matchedVehicle = vehicle;
            vehicles.push(matchedVehicle);
          }

          updatedTrips.push(Object.assign({
            participant: participant.fullName,
            vehicleLpnState: `${matchedVehicle.licensePlate}-${matchedVehicle.registrationState}`,
            pilotProgramName: `${pilotProgram.shortName}`,
          }, trip));
        }, Promise.resolve());
      });

      await Promise.all(processPromises);

      return updatedTrips;
    } catch (e) {
      console.log(e);
      throw new Error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              Trip Reports
            </Typography>
            <MultiSelect
              label="Participants"
              options={participants}
              optionsLabelKey="displayName"
              value={selectedParticipants}
              onChange={(e) => setSelectedParticipants(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="from"
              type="date"
              variant="outlined"
              fullWidth
              label="From"
              defaultValue={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <TextField
              name="to"
              type="date"
              variant="outlined"
              fullWidth
              label="To"
              defaultValue={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" >
            <CSVLink
              ref={csvLinkRef}
              data={csvData}
              filename={`RUC_Export_Trips.csv`}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              disabled={selectedParticipants.length === 0 || isSubmitting}
              onClick={async () => {
                const data = await query();
                console.log(data);
                const options = data.length > 0 ? {} : { fields: ['participant'] };
                const parser = new Parser(options);
                const csv = parser.parse(data);
                setCsvData(csv);
                await new Promise((resolve) => setTimeout(resolve, 300));
                csvLinkRef.current.link.click();
              }}
            >
              Download Trips Data
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
