const drivetu = {
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#0A4842',
      },
    },
    MuiInputAdornment: {
      textTransform: 'lowercase',
    },
  },
  palette: {
    primary: {
      light: '#00cc84',
      main: '#14A248',
      dark: '#0A4842',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ED9B2B',
    },
  },
};

const custom = {
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#ffffff',
        color: '#045594',
      },
    },
    MuiInputAdornment: {
      textTransform: 'lowercase',
    },
    MuiInputBase: {
      inputMultiline: {
        resize: 'vertical',
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      light: '#00cc84',
      main: '#045594',
      dark: '#045594',
    },
    secondary: {
      main: '#13784b',
    },
    success: {
      main: '#009f94',
    },
  },
  typography: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    h1: {
      fontFamily: 'Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    },
    h2: {
      fontFamily: 'Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    },
    h3: {
      fontFamily: 'Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    },
    h4: {
      fontFamily: 'Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    },
    h5: {
      fontFamily: 'Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    },
    h6: {
      fontFamily: 'Avant Garde, Avantgarde, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
    },
    body1: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    },
    body2: {
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    },
  },
};

export {
  custom,
  drivetu,
};
