import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import TransactionsTable from 'pages/Admin/components/TransactionsTable';

import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';

import { useChart } from 'utilities/hooks';
import { asyncListAll } from 'utilities/graph';
import { getTransactionsByUsernameByCreatedAt } from './queries';

import { useStyles } from './styles';

const ParticipantDashboard = ({ user }) => {
  const classes = useStyles();
  const { username } = user;

  const chartRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const transactions = await asyncListAll(getTransactionsByUsernameByCreatedAt, {
          username,
          createdAt: {
            between: [
              moment().subtract(11, 'months').startOf('month'),
              moment().subtract(1, 'months').endOf('month'),
            ],
          },
          sortDirection: 'DESC',
        }, {
          bypassCache: true,
        });
        setTransactions(transactions);
      } catch (e) {
        console.warn(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [username]);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      // group transactions by month
      const data = {};
      for (let i = 11, j = 0; i > 0; i -= 1, j += 1) {
        const month = moment().subtract(i, 'months');
        const inMonthTransactions = transactions.filter((t) => {
          return (
            moment(t.createdAt).isAfter(month.startOf('month')) &&
            moment(t.createdAt).isBefore(month.endOf('month'))
          );
        });

        // @todo convert units to imperial
        data[j] = {
          name: month.format('MMM \'YY'),
          data: inMonthTransactions.map((t) => {
            return t.amountCents / 100;
          }).reduce((acc, next) => {
            return acc + next;
          }, 0),
          extra: {
            // total segments with mileage fees (not toll)
            trips: inMonthTransactions.flatMap((t) => t.tripSegments.items).filter((s) => {
              return s.mileageFeeCents > 0;
            }).length,
            // reduced mileage across all segments
            mileage: inMonthTransactions.flatMap((t) => t.tripSegments.items).map((s) => {
              return s.mileage;
            }).reduce((acc, next) => {
              return acc + next;
            }, 0),
            // reduced fuel consumption across all segments
            fuel: inMonthTransactions.flatMap((t) => t.tripSegments.items).map((s) => {
              return s.fuel;
            }).reduce((acc, next) => {
              return acc + next;
            }, 0),
          },
        };
      }

      setChartData(data);
    }
  }, [transactions]);

  const activeMonthIndex = useChart(chartRef, chartData);

  if (isLoading) {
    return (
      <Grid container className={classes.root} justify="center" alignItems="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  if (transactions.length < 1) {
    return (
      <div className={classes.notFound}>
        <Typography component="h1" variant="h5">
          No Activity.
        </Typography>
        <p>It looks like you do not yet have any activity.  Please wait until the second day of the month following your MBUF Study start date.</p>
      </div>
    );
  }

  return (
    <Container component="main">
      <Alert style={{ display: 'none' }}></Alert>
      <div ref={chartRef}></div>
      {activeMonthIndex !== null && (
        <Grid container spacing={4}>
          <Grid item>
            <TransactionsTable
              title={`${chartData[activeMonthIndex].name} Transactions`}
              data={[...transactions].filter((t) => {
                const month = moment().subtract(11 - activeMonthIndex, 'months');
                return (
                  moment(t.createdAt).isAfter(month.startOf('month')) &&
                  moment(t.createdAt).isBefore(month.endOf('month'))
                );
              })}
              viewer="participant"
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

ParticipantDashboard.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    attributes: PropTypes.shape({
      email: PropTypes.string,
      given_name: PropTypes.string,
      family_name: PropTypes.string,
    }),
  }),
};

export default ParticipantDashboard;
