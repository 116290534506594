import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

function VehicleDetailsTooltip({ username, vehicle }) {
  const {
    vin = null,
    make = null,
    model = null,
    year = null,
    licensePlate = null,
    registrationState = null,
  } = vehicle;
  return (
    <Tooltip
      title={
        <table>
          <tr>
            <td>VIN</td>
            <td>{vin}</td>
          </tr>
          <tr>
            <td>Make</td>
            <td>{make}</td>
          </tr>
          <tr>
            <td>Model</td>
            <td>{model}</td>
          </tr>
          <tr>
            <td>Year</td>
            <td>{year}</td>
          </tr>
          <tr>
            <td>License Plate</td>
            <td>{licensePlate}</td>
          </tr>
          <tr>
            <td>Registration State</td>
            <td>{registrationState}</td>
          </tr>
        </table>
      }
      placement="right"
    >
      <IconButton
        size={'small'}
        onClick={() => {
          history.push(`/participant/${username}?tab=Vehicles`);
        }}
      >
        <DirectionsCarIcon />
      </IconButton>
    </Tooltip>
  );
}

VehicleDetailsTooltip.propTypes = {
  username: PropTypes.string,
  vehicle: PropTypes.object,
};

export default VehicleDetailsTooltip;
